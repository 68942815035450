import React from 'react';
import PigeonMap from 'pigeon-maps'
import PigeonMarker from './BoatJeMarker'


import turfBbox from '@turf/bbox'
import { featureCollection as turfFeatureCollection, point as turfPoint } from '@turf/helpers'
import geoViewport from '@mapbox/geo-viewport'

export default class Map extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            markers: [
                {
                    name: 'B1',
                    longitude: 52.5243109,
                    latitude: 4.7130023
                },
                {
                    name: 'B2',
                    longitude: 52.5285238,
                    latitude: 4.7201584
                },
                {
                    name: 'B3',
                    longitude: 52.547815,
                    latitude: 4.777995
                }
            ],
            dimensions: {
                width: 800,
                height: 600
            },
            selectedMarker: null
        };

        this.showMarkerInfo = this.showMarkerInfo.bind(this);
        this.hideMarkerInfo = this.hideMarkerInfo.bind(this);

    };

    componentDidMount() {
        if(typeof this.container !== 'undefined') {
            this.setState({
                dimensions: {
                    width: this.container.offsetWidth,
                    height: this.container.offsetHeight,
                },
            });
        }
    }

    centerZoomFromLocations(locations, width = 10, height = 10) {
        const points = locations.map(location => turfPoint([location.longitude, location.latitude]));
        const features = turfFeatureCollection(points);
        const bounds = turfBbox(features);

        const {center, zoom} = geoViewport.viewport(bounds, [width, height]);

        return {
            center: [center[0], center[1]],
            zoom: Math.min(zoom, 16)
        }
    }



    mapTilerProvider(x, y, z, dpr) {
        const MAPTILER_ACCESS_TOKEN = 'aGjK4jnf5HWsfLFReSNs';
        const MAP_ID = 'positron';
        return `https://api.maptiler.com/maps/${MAP_ID}/256/${z}/${x}/${y}${dpr >= 2 ? '@2x' : ''}.png?key=${MAPTILER_ACCESS_TOKEN}`;
    }

    showMarkerInfo(event){
        this.setState({
            'selectedMarker' : event.payload
        });
    }

    hideMarkerInfo(){
        this.setState({
            'selectedMarker': null
        });
    }


    render() {

        this.markers = this.state.markers.map((item, key) =>
            <PigeonMarker key={`marker_${key}`} anchor={[item.longitude, item.latitude]} payload={item} onClick={this.showMarkerInfo} className="BoatMarker"/>
        );

        this.mapState = this.centerZoomFromLocations(this.state.markers, this.state.dimensions.width, this.state.dimensions.height);

        const modalClass = 'modal' + (this.state.selectedMarker !== null ? ' is-active' : '');
        return (
            <div className="full-screen is-paddingless is-marginless" ref={el => (this.container = el)}>
                <PigeonMap center={this.mapState.center} zoom={this.mapState.zoom}
                            provider={this.mapTilerProvider} dprs={[1, 2]}>
                    {this.markers}
                </PigeonMap>
                <div className={modalClass} id="boatje-marker-modal">
                    <div className="modal-background" onClick={this.hideMarkerInfo}></div>
                    <div className="modal-card">
                        <header className="modal-card-head">

                            <p className="modal-card-title">{this.state.selectedMarker ? this.state.selectedMarker.name : ''}</p>
                            <button className="delete" aria-label="close" onClick={this.hideMarkerInfo}></button>
                        </header>
                        <section className="modal-card-body">
                            Test
                        </section>
                    </div>
                </div>
            </div>
        );
    }
}