import React from 'react';

export class Status extends React.Component {
    constructor(props)
    {
        super(props);

    };

    render(){
        return (
            <div className="container">
                Boot Status 1
            </div>
        );
    }
}

export default Status;