import React from 'react';
import './App.scss';
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import Nav from "./Nav";
import Map from "./Map/Map";
import Status from "./Status/Status";
import Account from "./Account/Account";

function App() {
    return (
        <div className="Boatje">
            <Router>
                <nav>
                    <Nav/>
                </nav>
                <section className="Boatje-content">
                    <Switch>
                        <Route path="/status">
                            <Status />
                        </Route>
                        <Route path="/map">
                            <Map />
                        </Route>
                        <Route path="/account">
                            <Account />
                        </Route>
                        <Route path="/">
                            <div className="container">
                                Home
                            </div>
                        </Route>
                    </Switch>
                </section>
            </Router>
        </div>
    );
}

export default App;
