import React from 'react';
import Marker from "pigeon-marker";

import pin from './img/pin.png'
import pinRetina from './img/pin2x.png'
import pinHover from './img/pin-hover.png'
import pinHoverRetina from './img/pin2x-hover.png'


export default class BoatJeMarker extends Marker{
    image () {
        return this.isRetina() ? (this.isHover() ? pinHoverRetina : pinRetina) : (this.isHover() ? pinHover : pin)
    }

    // lifecycle

    componentDidMount () {
        let images = this.isRetina() ? [
            pinRetina, pinHoverRetina
        ] : [
            pin, pinHover
        ]

        images.forEach(image => {
            let img = new window.Image()
            img.src = image
        })
    }

}