import React from 'react';
import {Link} from "react-router-dom";

import './Nav.scss';
import Logo from './assets/BoatJe.svg';
import LogoName from './assets/BoatJe-Text.svg';

function Nav() {
    return (
        <nav className="navbar is-fixed-bottom-touch" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <Link className="navbar-item is-expanded" to="/">
                    <img height={32} width={32} src={Logo} alt="BoatJe.nl" className="is-hidden-desktop"/>
                    <img height={32} width={120} src={LogoName} alt="BoatJe.nl" className="is-hidden-touch"/>
                </Link>
                <Link className="navbar-item is-expanded is-block-touch has-text-centered-touch" to="/map">
                    <span className="icon"><i className="fa fa-map"/></span>
                    <p className="is-size-7">Map</p>
                </Link>
                <Link className="navbar-item is-expanded  is-block-touch has-text-centered-touch" to="/status">
                    <span className="icon"><i className="fa fa-tachometer-alt"/></span>
                    <p className="is-size-7">Status</p>
                </Link>

                <Link className="navbar-item is-expanded  is-block-touch has-text-centered-touch" to="/history">
                    <span className="icon"><i className="fa fa-history"/></span>
                    <p className="is-size-7">History</p>
                </Link>

                <label role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" htmlFor="nav-toggle-state">
                    <span aria-hidden="true" />
                    <span aria-hidden="true" />
                    <span aria-hidden="true" />
                </label>
            </div>

            <input type="checkbox" id="nav-toggle-state" />

            <div className="navbar-menu">
                <div className="navbar-start">
                    <Link className="navbar-item is-expanded" to="/account">
                        <span className="icon"><i className="fa fa-user"/></span>
                        <p className="is-size-7 is-inline-touch">Account</p>
                    </Link>
                </div>

                <div className="navbar-end">
                    <Link className="navbar-item is-expanded" to="/history">
                        <span className="icon"><i className="fa fa-sign-in-alt"/></span>
                        <p className="is-size-7 is-inline-touch">Login</p>
                    </Link>
                </div>
            </div>


        </nav>
    );
}

export default Nav;